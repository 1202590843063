import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing-3'

//Helpers
import Title from "../helpers/Title"

const Numeros = ({ location }) => {
  let title = undefined
  let text = {
    sobrevivencia: {},
    aceleracao: {},
    partbr: {},
    finalbr: {},
    premiobr: {}
  }
  
  if (typeof location.state !== 'undefined'){
    title = location.state.title
    text = location.state.data
  }

  return (
  <ModalRoutingContext.Consumer>
    {({ modal, closeTo }) => (
      <Container>
        {modal ? (
          <Close to={closeTo}>&#9747;</Close>
        ) : (
          <Link to="/">Go back to the homepage</Link>
        )}

        <div className='modal__box'>
          <Title>{title}</Title>
          <Content>
            <p>
              {text.sobrevivencia.titulo}
            </p>
            <table>
              <tr>
                <td>{text.sobrevivencia.part}</td>
                <td>{text.sobrevivencia.etapa1}</td>
                <td>{text.sobrevivencia.final}</td>
              </tr>
              <tr>
                <td>{text.sobrevivencia.partn}</td>
                <td>{text.sobrevivencia.etapa1n}</td>
                <td>{text.sobrevivencia.finaln}</td>
              </tr>
            </table>

            <p>
              {text.aceleracao.titulo}
            </p>
            <table>
              <tr>
                <td>{text.aceleracao.part}</td>
                <td>{text.aceleracao.horas}</td>
              </tr>
              <tr>
                <td>{text.aceleracao.partn}</td>
                <td>{text.aceleracao.horasn}</td>
              </tr>
            </table>

            <p>
              {text.partbr.titulo}
            </p>
            <table>
              <tr>
                <td>{text.partbr.norte}</td>
                <td>{text.partbr.nordeste}</td>
                <td>{text.partbr.centro}</td>
                <td>{text.partbr.sudeste}</td>
                <td>{text.partbr.sul}</td>
              </tr>
              <tr>
                <td>{text.partbr.norten}</td>
                <td>{text.partbr.nordesten}</td>
                <td>{text.partbr.centron}</td>
                <td>{text.partbr.sudesten}</td>
                <td>{text.partbr.suln}</td>
              </tr>
            </table>

            <p>
              {text.finalbr.titulo}
            </p>
            <table>
              <tr>
                <td>{text.finalbr.norte}</td>
                <td>{text.finalbr.nordeste}</td>
                <td>{text.finalbr.centro}</td>
                <td>{text.finalbr.sudeste}</td>
                <td>{text.finalbr.sul}</td>
              </tr>
              <tr>
                <td>{text.finalbr.norten}</td>
                <td>{text.finalbr.nordesten}</td>
                <td>{text.finalbr.centron}</td>
                <td>{text.finalbr.sudesten}</td>
                <td>{text.finalbr.suln}</td>
              </tr>
            </table>

            <p>
              {text.premiobr.titulo}
            </p>
            <table>
              <tr>
                <td>{text.premiobr.norte}</td>
                <td>{text.premiobr.nordeste}</td>
                <td>{text.premiobr.centro}</td>
                <td>{text.premiobr.sudeste}</td>
                <td>{text.premiobr.sul}</td>
              </tr>
              <tr>
                <td>{text.premiobr.norten}</td>
                <td>{text.premiobr.nordesten}</td>
                <td>{text.premiobr.centron}</td>
                <td>{text.premiobr.sudesten}</td>
                <td>{text.premiobr.suln}</td>
              </tr>
            </table>
          </Content>
        </div>
      </Container>
    )}
  </ModalRoutingContext.Consumer>
)
        }
export default Numeros

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`

const Content = styled.div`
  width: 100%;
  font-family: var(--regular);
  font-size: 24px;
  color: #676767;
  text-align: center;
  line-height: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p{
      margin-bottom: 8px;
  }

  table{
    max-width: 100%;
    border-collapse: collapse;
    margin-bottom: 16px;
    font-size: 16px;
    color: var(--blue);

    td{
        padding: 6px 12px;
        border: 1px solid var(--blue);
    }
  }

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 20px;


    p{
      margin-bottom: 6px;
    }

    table{
      margin-bottom: 12px;
      font-size: 10px;
      line-height: 14px;


      td{
        padding: 2px 4px;
      }
    }
  }
`

const Close = styled(Link)`
  background: none;
  border: none;
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 2rem;
  color: var(--black);
  line-height: 1.5rem;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    top: -8px;
    right: -12px;
  }
`